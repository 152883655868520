import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  TextField,
  Typography,
  Grid,
  ThemeProvider,
  makeStyles,
  Button,
  Paper,
} from "@material-ui/core"
import { withFB } from "../Firebase/firebasecontext"
import SpinnerButton from "../BasicUiElements/SpinnerButton"
import logo from "../../images/DoryGo_logo.png"
import theme from "../Form/theme"
import "../globalStyles.css"
import { deDE } from "../../lang/de-DE"
import { deCH } from "../../lang/de-CH"

const useStyles = makeStyles(theme => ({
  formHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: "#FF3333",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: "center",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  logo:{
    contentAlign: 'center',
    width:"50%"
  },
  logoBox:{
    display:"flex", 
    flexDirection:"column" ,
    justifyContent:"center",
    alignItems:"center",
    textAlign: 'center',
  },
}))

/**
 *
 * @param {TypeProp} props
 * @returns {Object} React component
 */
function LoginBase(props) {
  const [form, setForm] = useState({
    email: "",
    password: "",
  })
  const [isFormValid, setIsFormValid] = useState(false)
  const [stateErrorMessage, setStateErrorMessage] = useState({
    email: "",
    password: "",
  })
  const [busy, setBusy] = useState(false)

  const [error, setError] = useState()

  const classes = useStyles()


  // set text
  let txt
  let errorsTxt
  if (props.country === "DE"|| props.country === "de") {
    txt = deDE.Login
    errorsTxt = deDE.Errors
  } else {
    txt = deCH.Login
    errorsTxt = deCH.Errors
  }

  //set navigation destination after login
  let destination
  switch(props.destination){
    case "onboarding":
      destination="/onboarding/?country="+props.country
      break
    case "service":
        destination="/service/?country="+props.country
        break
    default:
      console.log("Error:unknown/missing destination")
      destination="/"
      break
  }


  const mailRegex = new RegExp(/.+@.+\..+/)

  useEffect(() => {
    setForm({ ...form, ["email"]: props.email })
  }, [])

  //validate if form is completed and next button should be activated
  useEffect(() => {
    setIsFormValid(mailRegex.test(form.email) && form.password !== "")
  }, [form.email, form.password])

  const handleOnLoginClicked = () => {
    setBusy(true)
    const alreadyLoggedIn = props.firebase.isUserLoggedIn()
    if (!alreadyLoggedIn) {
      props.firebase
        .doSignInWithEmailAndPassword(form.email, form.password)
        .then(() => {
          navigate(destination)
          setBusy(false)
        })
        .catch(err => {
          if (err.code === "auth/wrong-password") {
            setError(txt.errorWrongPassword)
          } else if (err.code === "auth/too-many-requests") {
            setError(txt.errorTooManyRequests)
          } else if (err.code === "auth/user-not-found") {
            setError(txt.errorWrongPassword)
          } else {
            setError(txt.errorUnknown)
          }
          setBusy(false)
        })
    } else {
      props.firebase
        .doSignOut()
        .then(() => {
          props.firebase
            .doSignInWithEmailAndPassword(form.email, form.password)
            .then(() => {navigate(destination)
              setBusy(false)})
            .catch(err => {
              if (err.code === "auth/wrong-password") {
                setError(txt.errorWrongPassword)
              } else {
                setError(txt.errorUnknown)
              }
              setBusy(false)
            })
        })
        .catch(err => {
          setError(err.message)
          setBusy(false)
        })
    }
  }

  const handleChange = e => {
    //update form state
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const validateInput = name => {
    // validate Input
    switch (name) {
      case "email":
        if (form.email === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["email"]: txt.errorValueNeeded,
          })
        } else if (!mailRegex.test(form.email)) {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["email"]: txt.errorEmail,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["email"]: "" })
        }
        break
      case "password":
        if (form.password === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["password"]: txt.errorValueNeeded,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["password"]: "" })
        }
        break
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
      <div className={classes.layout}>
  <Paper className={classes.paper}>
    <div className={classes.logoBox}>
      <a href="https://dorygo.com">
        <img src={logo} width="100%" className={classes.logo} />
      </a>

    </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.formHeader}>
              {" "}
              {txt.login}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              type="email"
              error={stateErrorMessage.email !== "" ? true : false}
              variant="outlined"
              name="email"
              label={txt.labelEmail}
              helperText={stateErrorMessage.email}
              onChange={handleChange}
              onBlur={() => validateInput("email")}
              value={form.email}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="password"
              type="password"
              error={stateErrorMessage.password !== "" ? true : false}
              variant="outlined"
              name="password"
              label={txt.labelPassword}
              helperText={stateErrorMessage.password}
              onChange={handleChange}
              value={form.password}
              onBlur={() => validateInput("password")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <SpinnerButton
                variant="contained"
                color="primary"
                onClick={handleOnLoginClicked}
                className={classes.button}
                disabled={!isFormValid}
                loading={busy}
              >
                {txt.btnLoginTxt}
              </SpinnerButton>
              {error && (
                <div>
                  <Typography variant="body1" className={classes.errorMessage}>
                    {error}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        </Paper>
</div>
      </ThemeProvider>
    </>
  )
}


const Login = withFB(LoginBase)

export default Login
