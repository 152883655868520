import React from 'react'

import LayoutOB from '../components/layoutob.js'
import { useLocation } from '@reach/router'
import Login from '../components/Login'
import styled from "styled-components"

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`

export default function LoginPage() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    var destinationParam = searchParams.get("dest");

    return (
        <LayoutOB location={countryParam} language="de-CH">
        <Wrapper>
            <Login country={countryParam} destination={destinationParam}/>
        </Wrapper>
        </LayoutOB>
    )
}

